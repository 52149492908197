import React from 'react'

import { LoadingDots } from 'components/shared/Loading'
import Button from 'components/shared/Button'
import Card from 'components/shared/Card'
import { Alert, FormContainer } from 'components/shared/Forms'

export default ({ verificationCode, success, loading }) => {
  return (
    <FormContainer>
      <Card title="Verify Email">
        <LoadingDots loading={loading} />
        <Alert
          type="success"
          message="Successfully verified email"
          visible={success}
        />
        <Alert
          type="error"
          message="Failed to verify email"
          visible={success == false}
        />
        <Button big full to="/dashboard/settings">
          Go to dashboard
        </Button>
      </Card>
    </FormContainer>
  )
}

import React from 'react'

import styles from './Card.module.scss'

export default ({ title, subtitle, children }) => (
  <div className={styles.card}>
    <div className={styles.cardBody}>
      {title && (
        <div className={styles.cardHeader}>
          <div className={styles.cardTitle}>{title}</div>
          {subtitle && <div className={styles.cardSubtitle}>{subtitle}</div>}
        </div>
      )}
      {children}
    </div>
  </div>
)

import React, { useState } from 'react'
import { navigate } from 'gatsby'
import qs from 'qs'

import VerifyEmailForm from 'components/Auth/SignUp/VerifyEmail'
import Layout from 'layouts/AuthenticatedPage'
import SEO from 'components/shared/SEO'
import { AuthService, DatabaseService } from 'services'

function VerifyEmailPage(props) {
  const [success, setSuccess] = useState(null)

  const [loading, setLoading] = useState(true)

  const onSuccess = async () => {
    try {
      await DatabaseService.verifyEmail()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setSuccess(false)
      return error
    }
  }

  const checkCode = async code => {
    try {
      const response = await AuthService.verifyEmailCode(code)
      setSuccess(true)
    } catch (error) {
      setLoading(false)
      setSuccess(false)
      return error
    }
    onSuccess()
  }

  const storedURL = props.location.search.slice(1)
  if (storedURL) {
    const verificationCode = qs.parse(storedURL).oobCode
    checkCode(verificationCode)
  } else {
    navigate('/')
  }

  return <VerifyEmailForm success={success} loading={loading} />
}

export default ({ location }) => (
  <Layout location={location} displayFooter={false} showBeacon={true}>
    <SEO title="Verify Email" />
    <VerifyEmailPage location={location} />
  </Layout>
)
